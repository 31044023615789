@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./variables";

* {
  margin: 0;
  padding: 0;
}

html,
body,
* {
  font-family: $font-family;
}

html,
body {
  background: #fff;
  background-color: #fff !important;
  padding-right: 0px !important;
}

body {
  overflow-x: hidden;
}
.main {
  padding-top: 80px;
}
.loaderdiv {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 111;
  background: $white-color;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 110px;
    animation: zoom 3s infinite;
  }

  @keyframes zoom {
    0%,
    100% {
      transform: scale(0.8);
      opacity: 0;
    }

    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
.header_fixed {
  position: fixed !important;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1030;
  transition: all 1s ease-in-out !important;
  box-shadow: 0px 0px 20px 0px rgba($black-color, 0.08);
}
nav.navbar {
  min-height: 80px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: $white-color;
  transition: all 1s ease-in-out;
  .navbar-brand {
    margin-right: 25px;
    img.header_logo {
      height: 70px;
      @media only screen and (max-width: 991.98px) {
        height: 50px;
      }
    }
  }
  .navbar-collapse {
    .navbar_menu {
      a.nav-link {
        font-size: 16px;
        margin-right: 50px;
        color: $navlink-color;
        &:last-child {
          margin-right: 0px;
        }
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}
.mt_30 {
  margin-top: 30px;
}
.mt_40 {
  margin-top: 40px;
}
.mt_50 {
  margin-top: 50px;
}
.mb_10 {
  margin-bottom: 20px;
}
.mb_20 {
  margin-bottom: 20px;
}
.mb_30 {
  margin-bottom: 30px;
}
.mb_50 {
  margin-bottom: 50px;
}
.px_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.main_heading {
  font-size: 50px;
  font-weight: bold;
  color: #000;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  &.fw_200 {
    font-weight: 200;
  }
  &::after {
    position: absolute;
    content: "";
    bottom: 0px;
    left: 0px;
    width: 80px;
    height: 4px;
    background: $primary-color;
  }
  &.HowItWork_heading {
    margin-bottom: 40px;
    &::after {
      display: none;
    }
  }
  @media only screen and (max-width: 1199.98px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 767.98px) {
    font-size: 32px;
  }
}
.text_dark {
  color: $primary-color;
}
.banner_section {
  padding: 50px 0px;
  background: $primary-color;
  position: relative;
  z-index: 1;
  background-image: url("../img/bannerCircle_bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right -10%;
  .banner_content {
    .banner_logo {
      max-width: 400px;
      width: 100%;
      margin-bottom: 50px;
    }
    .downloadApp_box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      @media only screen and (max-width: 767.98px) {
        gap: 6px;
        justify-content: center;
      }
      .Appdownload_btn {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        background: #2f2f2f;
        border-radius: 10px;
        padding: 10px 15px;
        gap: 8px;
        &.android_btn {
          background: #005cb0;
        }
        @media only screen and (max-width: 991.98px) {
          padding: 10px;
        }
        @media only screen and (max-width: 767.98px) {
          padding: 8px;
          gap: 5px;
        }
        svg {
          flex-shrink: 0;
          font-size: 40px;
          color: $white-color;
          @media only screen and (max-width: 767.98px) {
            font-size: 30px;
          }
        }
        .Appdownload_cont {
          flex-grow: 1;
          h4 {
            font-size: 16px;
            font-weight: bold;
            color: $white-color;
            text-transform: uppercase;
            margin-bottom: 4px;
            @media only screen and (max-width: 767.98px) {
              font-size: 14px;
              margin-bottom: 2px;
            }
          }
          p {
            font-size: 14px;
            font-weight: 400;
            color: $white-color;
            text-transform: capitalize;
            margin-bottom: 0px;
            line-height: normal;
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .textsm_center {
    @media only screen and (max-width: 767.98px) {
      text-align: center;
    }
  }
  .banner_img {
    display: inline-block;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 50px;
      left: -100px;
      background-image: url("../img/bannerDots.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 120px;
      height: 137px;
      z-index: -1;
      @media only screen and (max-width: 991.98px) {
        background-size: contain;
        width: 70px;
        height: 97px;
        left: -30px;
      }
      @media only screen and (max-width: 767.98px) {
        left: 0px;
      }
    }
    &::before {
      position: absolute;
      content: "";
      bottom: 50px;
      right: -100px;
      background-image: url("../img/bannerDots.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 120px;
      height: 137px;
      z-index: -1;
      @media only screen and (max-width: 991.98px) {
        background-size: contain;
        width: 70px;
        height: 97px;
        right: -30px;
      }
      @media only screen and (max-width: 767.98px) {
        right: 0px;
      }
    }
    img {
      max-width: 300px;
      width: 100%;
    }
    @media only screen and (max-width: 991.98px) {
      margin-top: 40px;
      padding: 0px 25px;
    }
  }
}
.container {
  &.main_container {
    @media only screen and (min-width: 1200px) {
      max-width: 1190px;
    }
    @media only screen and (min-width: 1400px) {
      max-width: 1400px;
    }
  }
}
.Home_aboutus {
  padding: 100px 0px;
  @media only screen and (max-width: 991.98px) {
    padding: 50px 0px;
  }
  .HomeAbout_img {
    display: inline-block;
    position: relative;
    padding-right: 50px;
    padding-top: 50px;
    .aboutimg1 {
      max-width: 100%;
    }
    .aboutimg2 {
      max-width: 180px;
      position: absolute;
      top: 0px;
      right: 0px;
      @media only screen and (max-width: 630px) {
        max-width: 116px;
      }
    }
    @media only screen and (max-width: 991.98px) {
      margin-bottom: 20px;
    }
  }
  .HomeAbout_cont {
    p {
      font-size: 16px;
      font-weight: 400;
      color: #4a4a4a;
      margin-bottom: 15px;
    }
    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      li {
        font-size: 16px;
        font-weight: bold;
        color: #4a4a4a;
        margin-bottom: 5px;
        ul {
          list-style: disc;
          padding-left: 25px;
          padding-top: 15px;
          margin: 0px;
          li {
            font-size: 16px;
            font-weight: 400;
            color: #4a4a4a;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
.HowItWorks_sec {
  background: rgba($primary-color, 0.05);
  padding: 70px 0px;
  @media only screen and (max-width: 991.98px) {
    padding: 50px 0px;
  }
  img.workPattern_img {
    position: absolute;
    top: 50%;
    right: -27px;
    transform: translateY(-50%);
    max-width: 54px;
    @media only screen and (min-width: 1400px) {
      max-width: 60px;
      right: -30px;
    }
    @media only screen and (max-width: 1199.98px) {
      max-width: 32px;
      right: -16px;
    }
    @media only screen and (max-width: 991.98px) {
      display: none;
    }
  }
  .HowItWorks_div {
    text-align: center;
    background: $white-color;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 40px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    height: 315px;
    max-width: 315px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 1400px) {
      height: 335px;
      max-width: 335px;
    }
    @media only screen and (max-width: 1199.98px) {
      height: 280px;
      max-width: 280px;
      padding: 10px 20px;
    }
    @media only screen and (max-width: 991.98px) {
      height: 216px;
      max-width: 216px;
      padding: 10px 24px;
    }
    @media only screen and (max-width: 767.98px) {
      height: 280px;
      max-width: 280px;
      padding: 10px 24px;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 10px;
      color: $black-color;
      @media only screen and (max-width: 991.98px) {
        font-size: 16px;
        margin-bottom: 4px;
      }
    }
    p {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0px;
      color: #4a4a4a;
      @media only screen and (max-width: 991.98px) {
        font-size: 12px;
      }
    }
    .WorkCount_badge {
      position: absolute;
      top: 0px;
      right: 20px;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background: $primary-color;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $white-color;
      font-size: 26px;
      font-weight: bold;
      &::after {
        position: absolute;
        content: "";
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        border: 1px dashed $primary-color;
        border-radius: 50%;
        @media only screen and (max-width: 991.98px) {
          top: -6px;
          left: -6px;
          right: -6px;
          bottom: -6px;
        }
      }
      @media only screen and (max-width: 991.98px) {
        width: 40px;
        height: 40px;
        font-size: 18px;
      }
      @media only screen and (max-width: 767.98px) {
        right: 35px;
      }
    }
  }
}
.downloadApp_sec {
  padding: 80px 0px;
  @media only screen and (max-width: 991.98px) {
    padding: 50px 0px;
  }
  .downloadApp_info {
    @media only screen and (max-width: 767.98px) {
      padding-bottom: 20px;
    }
    p {
      font-size: 15px;
      font-weight: 300;
      color: #000;
      margin-bottom: 0px;
    }
    .downloadApp_box {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      @media only screen and (max-width: 767.98px) {
        gap: 6px;
        justify-content: center;
      }
      .Appdownload_btn {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        background: #2f2f2f;
        border-radius: 10px;
        padding: 10px 15px;
        gap: 8px;
        @media only screen and (max-width: 991.98px) {
          padding: 10px;
        }
        @media only screen and (max-width: 767.98px) {
          padding: 8px;
          gap: 5px;
        }
        &.android_btn {
          background: $primary-color;
        }
        svg {
          flex-shrink: 0;
          font-size: 40px;
          color: $white-color;
          @media only screen and (max-width: 767.98px) {
            font-size: 30px;
          }
        }
        .Appdownload_cont {
          flex-grow: 1;
          h4 {
            font-size: 16px;
            font-weight: bold;
            color: $white-color;
            text-transform: uppercase;
            margin-bottom: 4px;
            @media only screen and (max-width: 767.98px) {
              font-size: 14px;
              margin-bottom: 2px;
            }
          }
          p {
            font-size: 14px;
            font-weight: 400;
            color: $white-color;
            text-transform: capitalize;
            margin-bottom: 0px;
            line-height: normal;
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .downloadLogo_box {
    .downloadLogo_img {
      max-width: 100%;
    }
  }
}
footer {
  background: $primary-color;
  .footertop {
    padding: 60px 0px;
    @media only screen and (max-width: 767.98px) {
      padding: 50px 0px 20px;
    }
    .footer_info {
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 30px;
      }
      .footer_logo {
        height: 97.25px;
        margin-bottom: 25px;
      }
      p {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 30px;
        color: $white-color;
      }
    }
    .footer_nav {
      &.mt_115 {
        margin-top: 95px;
        @media only screen and (max-width: 767.98px) {
          margin-top: 0px;
        }
      }
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 30px;
      }
      h3 {
        font-size: 16px;
        font-weight: 600;
        color: $white-color;
        margin-bottom: 10px;
      }
      ul.nav {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        list-style: none;
        padding: 0px;
        margin: 0px;
        li.nav-item {
          padding: 0px;
          display: inline-block;
          a.nav-link {
            font-size: 15px;
            font-weight: 300;
            text-decoration: none;
            color: rgba($white-color, 0.8);
            display: block;
            padding: 0px;
            &:hover {
              color: $white-color;
            }
          }
        }
      }
    }
  }
  .copy_right {
    border-top: 1px solid rgba($white-color, 0.24);
    padding: 20px 0px;
    text-align: center;
    p {
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 0px;
      color: $white-color;
    }
  }
}
